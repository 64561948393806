<template>
  <div>
    <v-dialog v-model="complaintForm" class="px-3">
      <v-card>
        <v-card class="mx-auto pa-8" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div
                class="text-decoration-underline text-decoration-bold text-center mb-5 text-h3"
              >
                Form IV
              </div>
              <div class="text-h6 mb-1 text-center text-decoration-underline">
                STREET VENDORS TEN AN LUNGAWLOHNA THU THLENNA (Street Vendors
                Complaint)
              </div>
              <v-form class="mt-4">
                <validation-observer v-slot="{ invalid }">
                <v-row>
                  <v-col cols="12" md="6" lg="4">
                     <validation-provider
                                        name="Hming"
                                        v-slot="{ errors }"
                                        rules="required"
                                      >
                    <v-text-field
                      v-model="susv.name"
                      :error-messages="errors"
                      label="Hming"
                      prepend-icon="fas fa-user"
                      placeholder="Form apply-tu hming"
                    >
                    </v-text-field>
                     </validation-provider>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                   <validation-provider
                                        name="Address/chenna"
                                        v-slot="{ errors }"
                                        rules="required"
                                      >
                    <v-text-field
                    :error-messages="errors"
                      v-model="susv.address"
                      label="Address Kimchang"
                      placeholder="House No, Veng, Khua, District"
                      prepend-icon="fas fa-map-marker"
                    >
                    </v-text-field>
                   </validation-provider>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                   <validation-provider
                                        name="Street Vendor ID"
                                        v-slot="{ errors }"
                                        rules="required"
                                      >
                    <v-text-field
                    :error-messages="errors"
                      v-model="susv.id"
                      label="Street Vendor ID Number DAY-NULM pekchhuah"
                      placeholder="Id No"
                      prepend-icon="fas fa-id-card"
                    >
                    </v-text-field>
                   </validation-provider>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                   <validation-provider
                                        name="Certificate of Vending No. leh pekchhuah tanni"
                                        v-slot="{ errors }"
                                        rules="required"
                                      > 
                                      <v-text-field
                                      :error-messages="errors"
                      v-model="susv.cert"
                      label=""
                      placeholder="Street Vendor ID"
                      prepend-icon="fas fa-file"
                    >
                    </v-text-field>
                   </validation-provider>
                   
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <validation-provider
                                        name="Thilzawrhna hmun"
                                        v-slot="{ errors }"
                                        rules="required"
                                      > <v-text-field
                                      :error-messages="errors"
                      v-model="susv.storePlace"
                      label=""
                      placeholder="Thil zawrhna hmun"
                      prepend-icon="fas fa-map-marker-alt"
                    >
                    </v-text-field>
                   </validation-provider>
                   
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                     <validation-provider
                                        name="Thilzawrhna hmun Ward no/VC area No"
                                        v-slot="{ errors }"
                                        rules="required"
                                      > <v-text-field
                                      :error-messages="errors"
                      v-model="susv.storePlaceId"
                      label="Thilzawrhna hmun Ward no/VC area No"
                      placeholder="Area No"
                      prepend-icon="fas fa-warehouse"
                    >
                    </v-text-field>
                     </validation-provider>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                     <validation-provider
                                        name="Thilzawrh dan kalphung"
                                        v-slot="{ errors }"
                                        rules="required"
                                      >   <v-select
                                      :error-messages="errors"
                  
                      :items="[
                        'Thuthmun nghet neilo a kal kual',
                        'Thuthmun nghet nei/Hawkers',
                        'A dang'
                      ]"
                      v-model="susv.task"
                      label="Thilzawrh dan kalphung"
                      prepend-icon="fas fa-tasks"
                    >
                    </v-select>
                     </validation-provider>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                     <validation-provider
                                        name="Lungawi lohna thlentu thuneitu te"
                                        v-slot="{ errors }"
                                        rules="required"
                                      >   <v-select
                                      :error-messages="errors"
                      :items="[
                        'VC',
                        'LC',
                        'Police',
                        'Town Vending Committee',
                        'Midang'
                      ]"
                      v-model="susv.grievanceTo"
                      label="Lungawi lohna thlentu thuneitu te"
                      prepend-icon="fas fa-id-badge"
                    >
                    </v-select>
                     </validation-provider>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                      <validation-provider
                                        name="Lungawi lohna thlentu thuneitu te"
                                        v-slot="{ errors }"
                                        rules="required"
                                      > 
                    <v-textarea
                    :error-messages="errors"
                      v-model="susv.grievance"
                      label="Lungawih loh nachhan kimchang"
                      placeholder="Lungawih loh nachhan"
                      prepend-icon="fas fa-comments"
                      :rows="1"
                    >
                    </v-textarea>
                      </validation-provider>
                  </v-col>
                </v-row>  <div class="text-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="buttonGlass"
                  color="primary"
                  @click="complaintForm = false"
                  :disabled="invalid"
                  v-on="on"
                  v-bind="attrs"
                >
                  OK
                </v-btn>
              </template>

              <span>
                Form I duh anga i ziah fel vek hunah OK click tur
              </span>
            </v-tooltip>
          </div>
              </validation-observer>
              </v-form>
            </v-list-item-content>
          </v-list-item>

        
        </v-card>
      </v-card>
    </v-dialog>
    <div v-if="nulmSelect === 'SEP'">
      <v-row>
        <v-col cols="12" md="6">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Bank hming"
          >
            <v-select
              dense
              :readonly="$route.name == 'Notifications'"
              :items="bankArr"
              v-model="sep.bankName"
              class=""
              placeholder="Eg: State Bank of India"
              :error-messages="errors"
              label="Bank Hming"
              prepend-icon="fas fa-university"
              type="text"
              color="primary accent-3"
            ></v-select>
          </validation-provider>
        </v-col>
        <v-col cols="12" md="6">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Bank Branch"
          >
            <v-text-field
              dense
              :readonly="$route.name == 'Notifications'"
              class=""
              label="Bank Branch Hming"
              prepend-icon="fas fa-archway"
              :error-messages="errors"
              v-model="sep.bankBranch"
              placeholder="Eg: Dawrpui Branch"
            >
            </v-text-field>
          </validation-provider>
        </v-col>
        <v-col cols="12" md="12" class="pb-0">
          <validation-provider name="Feedback/Complaint" v-slot="{ errors }">
            <v-textarea
              :readonly="$route.name == 'Notifications'"
              :hide-details="$route.name == 'Notifications'"
              class=""
              label="Feedback/Complaint"
              rows="3"
              prepend-icon="fa fa-file-text-o"
              :error-messages="errors"
              v-model="sep.feedback"
              placeholder="Harsatna neihte"
              hint="I harsatna neihte hetah hian ziah luh tur a ni"
            ></v-textarea>
          </validation-provider>
        </v-col>
      </v-row>
    </div>
    <div v-if="nulmSelect === 'SUSV'">
      <v-row>
        <v-col v-if="$route.name == 'Login'">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                v-bind="attrs"
                v-on="on"
                class="buttonGlass"
                @click="complaintForm = true"
                >Submit Complaint</v-btn
              >
            </template>
            <span>
              Complaint thehluh duh chuan click tur
            </span>
          </v-tooltip>
        </v-col>
        <v-col v-if="$route.name !== 'Login'">
          <v-card class="mx-auto pa-8" outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <div
                  class="text-decoration-underline text-decoration-bold text-center text-h4 mb-5"
                >
                  Form IV
                </div>
                <div class="text-h6 mb-1 text-center text-decoration-underline">
                  STREET VENDORS TEN AN LUNGAWLOHNA THU THLENNA (Street Vendors
                  Complaint)
                </div>
                <!-- <validation-observer>
                  <v-form class="mt-4">
                    <v-row>
                      <v-col cols="12" md="6" lg="4">
                        <validation-provider
                          name="Name"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            :error-messages="errors"
                            v-model="susv.name"
                            label="Hming"
                            prepend-icon="fas fa-user"
                            placeholder="Form apply-tu hming"
                          >
                          </v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12" md="6" lg="4">
                        <v-text-field
                          v-model="susv.address"
                          label="Address Kimchang"
                          placeholder="House No, Veng, Khua, District"
                          prepend-icon="fas fa-map-marker"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" lg="4">
                        <v-text-field
                          v-model="susv.id"
                          label="Street Vendor ID Number DAY-NULM pekchhuah"
                          placeholder="Id No"
                          prepend-icon="fas fa-id-card"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" lg="4">
                        <v-text-field
                          v-model="susv.cert"
                          label="Certificate of Vending No. leh pekchhuah tanni"
                          placeholder="Street Vendor ID"
                          prepend-icon="fas fa-file"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" lg="4">
                        <v-text-field
                          v-model="susv.storePlace"
                          label="Thilzawrhna hmun"
                          placeholder="Thil zawrhna hmun"
                          prepend-icon="fas fa-map-marker-alt"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" lg="4">
                        <v-text-field
                          v-model="susv.storePlaceId"
                          label="Thilzawrhna hmun Ward no/VC area No"
                          placeholder="Area No"
                          prepend-icon="fas fa-warehouse"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" lg="6">
                        <v-select
                          :items="[
                            'Thuthmun nghet neilo a kal kual',
                            'Thuthmun nghet nei/Hawkers',
                            'A dang'
                          ]"
                          v-model="susv.task"
                          label="Thilzawrh dan kalphung"
                          prepend-icon="fas fa-tasks"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" md="6" lg="6">
                        <v-select
                          :items="[
                            'VC',
                            'LC',
                            'Police',
                            'Town Vending Committee',
                            'Midang'
                          ]"
                          v-model="susv.grievanceTo"
                          label="Lungawi lohna thlentu thuneitu te"
                          prepend-icon="fas fa-id-badge"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" md="12" lg="12">
                        <v-textarea
                          v-model="susv.grievance"
                          label="Lungawih loh nachhan kimchang"
                          placeholder="Lungawi loh nachhan"
                          prepend-icon="fas fa-comments"
                          :rows="1"
                        >
                        </v-textarea>
                      </v-col>
                    </v-row>
                  </v-form>
                </validation-observer> -->
             
               <validation-observer v-slot="{ invalid }">
                <v-row>
                  <v-col cols="12" md="6" lg="4">
                     <validation-provider
                                        name="Hming"
                                        v-slot="{ errors }"
                                        rules="required"
                                      >
                    <v-text-field
                      v-model="susv.name"
                      :error-messages="errors"
                      label="Hming"
                      prepend-icon="fas fa-user"
                      placeholder="Form apply-tu hming"
                    >
                    </v-text-field>
                     </validation-provider>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                   <validation-provider
                                        name="Address/chenna"
                                        v-slot="{ errors }"
                                        rules="required"
                                      >
                    <v-text-field
                    :error-messages="errors"
                      v-model="susv.address"
                      label="Address Kimchang"
                      placeholder="House No, Veng, Khua, District"
                      prepend-icon="fas fa-map-marker"
                    >
                    </v-text-field>
                   </validation-provider>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                   <validation-provider
                                        name="Street Vendor ID"
                                        v-slot="{ errors }"
                                        rules="required"
                                      >
                    <v-text-field
                    :error-messages="errors"
                      v-model="susv.id"
                      label="Street Vendor ID Number DAY-NULM pekchhuah"
                      placeholder="Id No"
                      prepend-icon="fas fa-id-card"
                    >
                    </v-text-field>
                   </validation-provider>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                   <validation-provider
                                        name="Certificate of Vending No. leh pekchhuah tanni"
                                        v-slot="{ errors }"
                                        rules="required"
                                      > 
                                      <v-text-field
                                      :error-messages="errors"
                      v-model="susv.cert"
                      label=""
                      placeholder="Street Vendor ID"
                      prepend-icon="fas fa-file"
                    >
                    </v-text-field>
                   </validation-provider>
                   
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                    <validation-provider
                                        name="Thilzawrhna hmun"
                                        v-slot="{ errors }"
                                        rules="required"
                                      > <v-text-field
                                      :error-messages="errors"
                      v-model="susv.storePlace"
                      label=""
                      placeholder="Thil zawrhna hmun"
                      prepend-icon="fas fa-map-marker-alt"
                    >
                    </v-text-field>
                   </validation-provider>
                   
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                     <validation-provider
                                        name="Thilzawrhna hmun Ward no/VC area No"
                                        v-slot="{ errors }"
                                        rules="required"
                                      > <v-text-field
                                      :error-messages="errors"
                      v-model="susv.storePlaceId"
                      label="Thilzawrhna hmun Ward no/VC area No"
                      placeholder="Area No"
                      prepend-icon="fas fa-warehouse"
                    >
                    </v-text-field>
                     </validation-provider>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                     <validation-provider
                                        name="Thilzawrh dan kalphung"
                                        v-slot="{ errors }"
                                        rules="required"
                                      >   <v-select
                                      :error-messages="errors"
                  
                      :items="[
                        'Thuthmun nghet neilo a kal kual',
                        'Thuthmun nghet nei/Hawkers',
                        'A dang'
                      ]"
                      v-model="susv.task"
                      label="Thilzawrh dan kalphung"
                      prepend-icon="fas fa-tasks"
                    >
                    </v-select>
                     </validation-provider>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                     <validation-provider
                                        name="Lungawi lohna thlentu thuneitu te"
                                        v-slot="{ errors }"
                                        rules="required"
                                      >   <v-select
                                      :error-messages="errors"
                      :items="[
                        'VC',
                        'LC',
                        'Police',
                        'Town Vending Committee',
                        'Midang'
                      ]"
                      v-model="susv.grievanceTo"
                      label="Lungawi lohna thlentu thuneitu te"
                      prepend-icon="fas fa-id-badge"
                    >
                    </v-select>
                     </validation-provider>
                  </v-col>
                  <v-col cols="12" md="6" lg="4">
                      <validation-provider
                                        name="Lungawi lohna thlentu thuneitu te"
                                        v-slot="{ errors }"
                                        rules="required"
                                      > 
                    <v-textarea
                    :error-messages="errors"
                      v-model="susv.grievance"
                      label="Lungawih loh nachhan kimchang"
                      placeholder="Lungawih loh nachhan"
                      prepend-icon="fas fa-comments"
                      :rows="1"
                    >
                    </v-textarea>
                      </validation-provider>
                  </v-col>
                </v-row>  <div class="text-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="buttonGlass"
                  color="primary"
                  @click="complaintForm = false"
                  :disabled="invalid"
                  v-on="on"
                  v-bind="attrs"
                >
                  OK
                </v-btn>
              </template>

              <span>
                Form I duh anga i ziah fel vek hunah OK click tur
              </span>
            </v-tooltip>
          </div>
              </validation-observer>
              </v-list-item-content>
            </v-list-item>

            <!-- <div class="text-center" v-if="$route.name !== 'Grievances'">
            <v-btn color="primary"
                          class="buttonGlass mt-0">
              Submit
            </v-btn>
          </div> -->
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div v-if="nulmSelect === 'ESTP'" class="ma-0 pa-0">
      <validation-observer>
        <v-row>
          <v-col cols="12" md="6">
            <validation-provider
              name="Training Center"
              rules="required"
              v-slot="{ errors }"
            >
              <v-text-field
                dense
                class=""
                label="Training Center"
                prepend-icon="fas fa-university"
                :error-messages="errors"
                v-model="estp.trainingCenter"
                placeholder="Training Center"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" md="6">
            <validation-provider
              name="Course"
              rules="required"
              v-slot="{ errors }"
            >
              <v-text-field
                dense
                class=""
                label="Course"
                prepend-icon="fas fa-book"
                :error-messages="errors"
                v-model="estp.course"
                placeholder="Course"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" md="6">
            <validation-provider
              name="District"
              rules="required"
              v-slot="{ errors }"
            >
              <v-select
                dense
                class=""
                label="District"
                :items="District"
                prepend-icon="fas fa-map-marker"
                :error-messages="errors"
                v-model="estp.trainingCenterDistrict"
                placeholder="Training Center"
              ></v-select>
            </validation-provider>
          </v-col>
          <v-col cols="12" md="6">
            <validation-provider
              name="Subsidy dawn dan"
              rules="required"
              v-slot="{ errors }"
            >
              <v-select
                class=""
                dense
                label="Subsidy"
                :items="['Dawng Tha', 'Dawng Tha lo']"
                prepend-icon="fas fa-inr"
                :error-messages="errors"
                v-model="estp.subsidy"
                placeholder="Dawng tha/thalo"
              ></v-select>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row class="my-0 py-0">
          <v-col cols="12" md="12" class="pb-0">
            <validation-provider name="Feedback/Complaint" v-slot="{ errors }">
              <v-textarea
                class=""
                label="Feedback/Complaint"
                rows="3"
                prepend-icon="fa fa-file-text-o"
                :error-messages="errors"
                v-model="estp.feedback"
                placeholder="Harsatna neihte"
                hint="I harsatna neihte hetah hian ziah luh tur a ni"
              ></v-textarea>
            </validation-provider>
          </v-col>
        </v-row>
      </validation-observer>
    </div>
  </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
export default {
  components: {
    ValidationObserver,
    ValidationProvider
  },
  props: ['sep', 'estp', 'susv', 'nulmSelect'],
  data: () => ({
    District: [
      'Aizawl',
      'Kolasib',
      'Lawngtlai',
      'Lunglei',
      'Mamit',
      'Saiha',
      'Serchhip',
      'Champhai',
      'Hnahthial',
      'Khawzawl',
      'Saitual'
    ],
    complaintForm: false,
    bankArr: [
      'Axis Bank',
      'Bandhan Bank',
      'Bank of Baroda',
      'Bank of India',
      'Bank of Maharashtra',
      'Canara Bank',
      'Central Bank of India',
      'City Union Bank',
      'CSB Bank',
      'DCB Bank',
      'Dhanlaxmi Bank',
      'Federal Bank',
      'HDFC Bank',
      'ICICI Bank',
      'IDBI Bank',
      'IDFC First Bank',
      'Indian Bank',
      'Indian Overseas Bank',
      'IndusInd Bank',
      'Karnataka Bank',
      'Karur Vysya Bank',
      'Kotak Mahindra Bank',
      'MUCO',
      'Mizoram Rural Bank',
      'Nainital Bank',
      'Punjab and Sind Bank',
      'Punjab National Bank',
      'RBL Bank',
      'South Indian Bank',
      'State Bank of India',
      'Tamilnad Mercantile Bank',
      'UCO Bank',
      'Union Bank of India',
      'Yes Bank'
    ]
  })
}
</script>
<style>
.buttonGlass {
  box-sizing: border-box;
  overflow: hidden;
}
.buttonGlass::before {
  position: absolute;
  left: 0%;
  width: 20%;
  backdrop-filter: blur(2px);
  transform: skewX(-40deg) translateX(-3rem);
  background-color: white;
  opacity: 0.4 !important;
  z-index: 2;
  transition: 0.4s ease-out;
  border-radius: none !important;
}
.buttonGlass:hover::before {
  left: 100%;
  transform: skewX(-40deg) translateX(1rem);
}
</style>
