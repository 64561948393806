<template>
  <v-app class="bgSvg">
    <div class="logoBanner white--text">MzSULM</div>
    <div class="left"></div>
    <div class="right"></div>
    <div id="recaptcha-container"></div>

    <v-alert
      min-width="100vw"
      v-if="error"
      dismissible
      type="error"
      style="z-index:10;position:absolute"
      >{{ error }}</v-alert
    >

    <v-dialog v-model="showLoading" width="50%" persistent class="px-3">
      <v-card class="text-center px-5" style="height:50px" tile>
        {{ progressMessages }}
        <v-progress-linear
          :value="progressValue"
          height="5"
          color="primary"
          class="px-n5"
        ></v-progress-linear>
      </v-card>
    </v-dialog>
    <v-spacer></v-spacer>
    <div class="ma-0 pa-0">
      <v-container
        class="fill-height"
        fluid
        :class="{ 'mt-10': $vuetify.breakpoint.mobile }"
      >
        <v-row align="center" justify="center">
          <v-col cols="12" xs="12" sm="12" md="8" name="main_login">
            <v-window v-model="step" class="ma-0 pa-0">
              <v-window-item :value="1" class="ma-0 pa-0">
                <v-row
                  no-gutters
                  class="fill-height loginWave bg-black"
                  justify-content="center"
                  background-color="transparent"
                >
                  <v-col
                    id="login_main"
                    cols="12"
                    md="8"
                    class=" "
                    align-self="center"
                    style="overflow:auto"
                  >
                    <v-flex justify-center align class="loginWave">
                      <v-card-text
                        class="pt-0 "
                        style="padding-top:1rem;display: flex !important;
  flex-direction: column;"
                        :overflow="false"
                      >
                        <h1
                          class="text-center .text-decoration-underline primary--text display-2 accent--2 mt-0 mb-4"
                          :class="{ 'mt-2': $vuetify.breakpoint.mobile }"
                        >
                          <u>Sign in</u>
                        </h1>
                        <v-tabs
                          v-model="tab"
                          color="primary"
                          centered
                          height="4.5rem"
                          width="4.5rem"
                          outline
                          dense
                          background-opacity="100"
                          center-active
                          slider-color="primary"
                          background-color="transparent"
                        >
                          <v-tab
                            style="border-shadow:none;overflow:hidden"
                            class="pa-4"
                          >
                            <v-hover v-slot="{ hover }">
                              <v-btn
                                class=" fabBtn "
                                fab
                                text
                                :outlined="!hover"
                                :ripple="hover"
                              >
                                <v-icon
                                  dense
                                  v-bind:color="hovertest(hover)"
                                  class="iconBtn mr-4 pr-8"
                                  >fab fa-google</v-icon
                                >
                                <svg
                                  style="z-index:10"
                                  width="70"
                                  class="ml-n12"
                                >
                                  <circle
                                    stroke="white"
                                    class="circle"
                                    cx="50%"
                                    cy="50%"
                                    r="20%"
                                    stroke-width="3"
                                    fill-opacity="0"
                                  />
                                </svg> </v-btn
                            ></v-hover>
                          </v-tab>
                          <v-tab align-with-title>
                            <v-hover v-slot="{ hover }">
                              <v-btn
                                class=" fabBtn "
                                fab
                                text
                                :outlined="!hover"
                                :ripple="hover"
                              >
                                <v-icon
                                  dense
                                  v-bind:color="hovertest(hover)"
                                  class="iconBtn mr-4 pr-8"
                                  >fas fa-phone</v-icon
                                >
                                <svg
                                  style="z-index:10"
                                  width="70"
                                  class="ml-n12"
                                >
                                  <circle
                                    stroke="white"
                                    class="circle"
                                    cx="50%"
                                    cy="50%"
                                    r="20%"
                                    stroke-width="3"
                                    fill-opacity="0"
                                  />
                                </svg> </v-btn
                            ></v-hover>
                          </v-tab>
                          <v-tab align-with-title>
                            <v-hover v-slot="{ hover }">
                              <v-btn
                                class=" fabBtn "
                                fab
                                text
                                :outlined="!hover"
                                :ripple="hover"
                              >
                                <v-icon
                                  dense
                                  v-bind:color="hovertest(hover)"
                                  class="iconBtn mr-4 pr-8"
                                  >fas fa-envelope</v-icon
                                >
                                <svg
                                  style="z-index:10"
                                  width="70"
                                  class="ml-n12"
                                >
                                  <circle
                                    stroke="white"
                                    class="circle"
                                    cx="50%"
                                    cy="50%"
                                    r="20%"
                                    stroke-width="3"
                                    fill-opacity="0"
                                  />
                                </svg> </v-btn
                            ></v-hover>
                          </v-tab>
                        </v-tabs>

                        <v-tabs-items v-model="tab" dense>
                          <v-tab-item class="tableData">
                            <validation-observer
                              v-slot="{ invalid }"
                              ref="google"
                            >
                              <v-form
                                class="mt-6"
                                background-color="black"
                                ref="google_login"
                              >
                                <v-row>
                                  <v-col cols="12" md="6">
                                    <validation-provider
                                      name="NULM"
                                      v-slot="{ errors }"
                                      rules="required"
                                    >
                                      <v-select
                                        :error-messages="errors"
                                        :items="NULM"
                                        v-model="nulmSelect"
                                        label="DAY-NULM"
                                        persistent-hint
                                        hint="I DAY-NULM thlan tur"
                                        class="ml-7"
                                        :class="{
                                          'mr-6': $vuetify.breakpoint.mobile
                                        }"
                                        prepend-icon="fas fa-university"
                                      ></v-select>
                                    </validation-provider>
                                  </v-col>
                                  <v-col>
                                    <validation-provider
                                      name="District"
                                      v-slot="{ errors }"
                                      rules="required"
                                    >
                                      <v-select
                                        :error-messages="errors"
                                        class="ml-7 mr-7"
                                        :class="{
                                          ' mr-5': $vuetify.breakpoint.mobile
                                        }"
                                        label="District chenna"
                                        :items="District"
                                        v-model="userDistrict"
                                        prepend-icon="fas fa-map-marker"
                                      >
                                      </v-select></validation-provider
                                  ></v-col>
                                </v-row>
                                <div align="center" class="mt-4 mb-5">
                                  <v-btn
                                    :small="$vuetify.breakpoint.mobile"
                                    :disabled="invalid"
                                    class="mt-4 "
                                    color="primary accent-3"
                                    v-on:click="showGoogle"
                                    >SIGN IN</v-btn
                                  >
                                </div>
                              </v-form>
                            </validation-observer>
                          </v-tab-item>
                          <v-tab-item class="tableData">
                            <v-form class="pa-5 pt-0" ref="phone_login">
                              <validation-observer
                                v-slot="{ invalid }"
                                ref="phone_login"
                              >
                                <v-row>
                                  <v-col cols="12" md="6">
                                    <validation-provider
                                      name="NULM"
                                      v-slot="{ errors }"
                                      rules="required"
                                    >
                                      <v-select
                                        :error-messages="errors"
                                        :items="NULM"
                                        v-model="nulmSelect"
                                        label="DAY-NULM"
                                        persistent-hint
                                        hint="I DAY-NULM thlan tur"
                                        class="ml-7"
                                        :class="{
                                          'mr-6': $vuetify.breakpoint.mobile
                                        }"
                                        prepend-icon="fas fa-university"
                                      ></v-select>
                                    </validation-provider>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                    <validation-provider
                                      name="District"
                                      v-slot="{ errors }"
                                      rules="required"
                                    >
                                      <v-select
                                        :error-messages="errors"
                                        class="ml-6 mr-6"
                                        :class="{
                                          ' mr-5': $vuetify.breakpoint.mobile
                                        }"
                                        label="District chenna"
                                        :items="District"
                                        v-model="userDistrict"
                                        prepend-icon="fas fa-map-marker"
                                      >
                                      </v-select></validation-provider
                                  ></v-col>
                                </v-row>
                                <v-row>
                                  <v-col
                                    cols="12"
                                    md="6"
                                    class="pa-0 text-center "
                                  >
                                    <validation-provider
                                      class="ma-0 pa-0"
                                      v-slot="{ errors }"
                                      name="Phone No"
                                      rules="phonerules||required"
                                    >
                                      <v-text-field
                                        class="mx-8"
                                        prefix="91"
                                        prepend-icon="fas fa-phone"
                                        :error-messages="errors"
                                        v-model="phNo"
                                        placeholder="Phone Number"
                                      ></v-text-field>
                                    </validation-provider>
                                  </v-col>
                                  <v-col
                                    class="pa-0 text-center"
                                    cols="12"
                                    md="6"
                                  >
                                    <validation-provider>
                                      <v-text-field
                                        :disabled="otpLoginStep == 0"
                                        class="mx-8"
                                        prepend-icon="fas fa-lock"
                                        v-model="otp"
                                        required
                                        placeholder="OTP"
                                      ></v-text-field
                                    ></validation-provider>
                                  </v-col>
                                </v-row>

                                <v-row class="text-center">
                                  <v-col cols="12" md="6" class="">
                                    <v-btn
                                      :class="{
                                        buttonGlass: !invalid
                                      }"
                                      :disabled="invalid"
                                      color="primary"
                                      @click="verifyOtp()"
                                    >
                                      Get OTP
                                    </v-btn>
                                  </v-col>

                                  <v-col cols="12" md="6" class="text-center">
                                    <v-btn
                                      :class="{
                                        buttonGlass: otpLoginStep == 1
                                      }"
                                      :disabled="otpLoginStep == 0"
                                      color="primary"
                                      @click="verifyOtp()"
                                      >Verify OTP</v-btn
                                    >
                                  </v-col>
                                </v-row>
                              </validation-observer>
                            </v-form>
                          </v-tab-item>
                          <v-tab-item class="tableData">
                            <validation-observer
                              v-slot="{ invalid }"
                              ref="main_login"
                              ><v-form v-model="formData" ref="email_login">
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="Email"
                                  rules="required|email"
                                >
                                  <v-text-field
                                    v-model="email"
                                    class="mx-8"
                                    label="Email"
                                    name="Email"
                                    :error-messages="errors"
                                    prepend-icon="fas fa-envelope"
                                    type="text"
                                    color="primary accent-3"
                                    hint="Enter your registered E-mail ID"
                                  ></v-text-field>
                                </validation-provider>
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="Password"
                                  :rules="{
                                    required: true,
                                    one: 6
                                  }"
                                >
                                  <v-text-field
                                    v-model="password"
                                    class="mx-8"
                                    :error-messages="errors"
                                    label="Password"
                                    name="Password"
                                    prepend-icon="fas fa-unlock-alt"
                                    color="primary accent-3"
                                    :append-icon="
                                      seenPass
                                        ? 'fas fa-eye-slash'
                                        : 'fas fa-eye'
                                    "
                                    @click:append="() => (seenPass = !seenPass)"
                                    :type="seenPass ? 'text' : 'password'"
                                    hint="Enter your Password"
                                  ></v-text-field>
                                </validation-provider>
                                <v-row>
                                  <v-col cols="12" md="6">
                                    <validation-provider
                                      name="NULM"
                                      v-slot="{ errors }"
                                      rules="required"
                                    >
                                      <v-select
                                        :error-messages="errors"
                                        :items="NULM"
                                        v-model="nulmSelect"
                                        label="DAY-NULM"
                                        persistent-hint
                                        hint="I DAY-NULM thlan tur"
                                        class="ml-7"
                                        :class="{
                                          'mr-6': $vuetify.breakpoint.mobile
                                        }"
                                        prepend-icon="fas fa-university"
                                      ></v-select>
                                    </validation-provider>
                                  </v-col>
                                  <v-col>
                                    <validation-provider
                                      name="District"
                                      v-slot="{ errors }"
                                      rules="required"
                                    >
                                      <v-select
                                        :error-messages="errors"
                                        class="ml-7 mr-7"
                                        :class="{
                                          ' mr-5': $vuetify.breakpoint.mobile
                                        }"
                                        label="District chenna"
                                        :items="District"
                                        v-model="userDistrict"
                                        prepend-icon="fas fa-map-marker"
                                      >
                                      </v-select></validation-provider
                                  ></v-col>
                                </v-row>
                                <div align="center" class="mt-n5 mb-5">
                                  <v-btn
                                    :disabled="invalid"
                                    class="buttonGlass"
                                    color="primary accent-3"
                                    v-on:click="signIn()"
                                    >SIGN IN</v-btn
                                  >
                                </div>
                                <h3 class="text-center my-3">
                                  <a href="/#/PasswordForget">
                                    <u><i>Forget Password </i></u>
                                  </a>
                                </h3></v-form
                              ></validation-observer
                            >
                          </v-tab-item>
                        </v-tabs-items>
                      </v-card-text>
                    </v-flex>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    class=" primary ma-0 pa-0 loginWave"
                    style="min-height:73vh; "
                  >
                    <div class="ma-0 pa-0 ">
                      <v-card-text class="white--text  mb-0">
                        <h1
                          class="text-center  pt-6 pb-0"
                          style="line-height:2rem"
                        >
                          <u>New User SignUp</u>
                        </h1>
                        <div class="text-center my-5 mb-8 mt-0">
                          <v-btn
                            outlined
                            dark
                            @click="step++"
                            class="mt-5 buttonGlass"
                            >SIGN UP</v-btn
                          >
                        </div>
                        <v-divider
                          color="white"
                          v-if="!$vuetify.breakpoint.mobile"
                        ></v-divider>
                        <h1
                          class="text-center  my-9 "
                          v-if="!$vuetify.breakpoint.mobile"
                        >
                          Welcome Back
                        </h1>
                        <h4
                          class="text-center"
                          v-if="!$vuetify.breakpoint.mobile"
                        >
                          Enter Your Email And Password To Continue
                        </h4>
                      </v-card-text>
                    </div>
                    <v-divider class="white mx-3"></v-divider>
                    <v-spacer></v-spacer>
                    <v-row align-content="end" justify="end">
                      <v-col absolute class="text-center white--text mt-7">
                        <v-btn
                          outlined
                          dark
                          to="ContactUs"
                          class="buttonGlass pa-2"
                          >Contact Us</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-window-item>
              <v-window-item :value="2">
                <v-row class="fill-height">
                  <v-col
                    cols="12"
                    md="4"
                    class="primary accent-3 ma-0 pa-0"
                    style="min-height:76.4vh; overflow:auto;"
                  >
                    <v-card-text class="white--text mt-7  mx-1">
                      <div class="text-center">
                        <v-btn outlined dark @click="step--" class="buttonGlass"
                          >SIGN IN</v-btn
                        >
                      </div>
                      <v-divider class="white mt-3"></v-divider>
                      <h1
                        class="text-center mb-5 mt-3"
                        style="line-height:120%"
                      >
                        Welcome New User
                      </h1>
                      <h4 class="text-center">
                        Please Enter Your Details In The Forms Provided
                      </h4>
                      <v-divider color="white" class="mt-5"></v-divider>
                      <h4
                        class="text-center mt-4"
                        v-if="$vuetify.breakpoint.name !== 'xs'"
                      >
                        Hints are provided here: {{ hints(e1) }}
                      </h4>
                    </v-card-text>
                  </v-col>
                  <v-col
                    cols="12"
                    md="8"
                    class=" pt-0 pb-0 mb-0 px-2 mt-0"
                    align-self="center"
                    style="min-height:70vh; max-height:100vh;overflow:auto"
                  >
                    <v-card class="elevation-0  pa-0 ma-0" flat>
                      <v-stepper
                        dense
                        class="elevation-0 ma-0 pa-0"
                        v-model="e1"
                        max-width="100%"
                        flat
                      >
                        <v-stepper-header
                          dense
                          class="grey-lightn2 ma-0 pa-0 elevation-0"
                        >
                          <template v-for="step in steps">
                            <v-stepper-step
                              :key="step.step"
                              :complete="e1 > step.step"
                              :step="step.step"
                              :editable="step.step <= maxStep"
                              class="white--text ma-0 pa-0 px-5"
                              :class="{
                                'grey lighten-3 rounded': step.step === e1
                              }"
                            >
                              <template v-if="!$vuetify.breakpoint.mobile">
                                {{ step.name }}</template
                              >
                            </v-stepper-step>

                            <v-divider
                              v-if="step.step < 3"
                              :key="step.name"
                              class=""
                              :class="{ primary: step.step <= e1 }"
                            ></v-divider>
                          </template>
                        </v-stepper-header>
                        <v-divider class="primary mx-0 mb-4"></v-divider>
                        <v-stepper-items class="">
                          <v-stepper-content :step="1" class="pt-0">
                            <v-card
                              class="mt-0 elevation-0 px-0"
                              min-height="100%"
                            >
                              <validation-observer
                                ref="observer2"
                                v-slot="secstepinvalid"
                              >
                                <div class="tableData pb-5">
                                  <v-form
                                    class="mt-3"
                                    ref="form1"
                                    @submit.prevent="submit"
                                  >
                                    <validation-provider
                                      v-slot="{ errors }"
                                      name="name"
                                      rules="required|name"
                                    >
                                      <v-text-field
                                        :class="{
                                          'pt-2': $vuetify.breakpoint.mobile
                                        }"
                                        :error-messages="errors"
                                        prepend-icon="fas fa-user"
                                        class="mx-5"
                                        ref="name"
                                        v-model="name"
                                        @change="nameUpper(name)"
                                        label="Name"
                                        hint="Enter Your Name"
                                        required
                                      ></v-text-field
                                    ></validation-provider>
                                    <validation-provider
                                      v-slot="{ errors }"
                                      name="Address"
                                      rules="required"
                                    >
                                      <v-text-field
                                        :class="{
                                          'pt-2': $vuetify.breakpoint.mobile
                                        }"
                                        :error-messages="errors"
                                        prepend-icon="fas fa-map-marker-alt"
                                        class="mx-5"
                                        ref="name"
                                        v-model="address"
                                        label="Address"
                                        hint="Enter Your Address"
                                        required
                                      ></v-text-field
                                    ></validation-provider>
                                    <v-row class="ma-0 pa-0">
                                      <v-col class="ma-0 pa-0">
                                        <validation-provider
                                          v-slot="{ errors }"
                                          name="Contact Number"
                                          rules="phoneNo|required|phonerules"
                                        >
                                          <v-text-field
                                            :class="{
                                              'pt-2': $vuetify.breakpoint.mobile
                                            }"
                                            prepend-icon="fas fa-phone"
                                            ref="phoneNo"
                                            :error-messages="errors"
                                            v-model="phoneNo"
                                            class="mx-5"
                                            label="Phone No"
                                            hint="Enter Your Phone No"
                                            required
                                            prefix="91"
                                          ></v-text-field></validation-provider
                                      ></v-col>
                                      <v-col class="ma-0 pa-0">
                                        <validation-provider
                                          rules="required"
                                          class="ma-0 pa-0"
                                          name="NULM"
                                          v-slot="{ errors }"
                                        >
                                          <v-select
                                            :error-messages="errors"
                                            :class="{
                                              'pt-2': $vuetify.breakpoint.mobile
                                            }"
                                            :items="NULM"
                                            v-model="nulmSelect"
                                            label="Select your NULM"
                                            hint="I NULM thlan tur"
                                            class="mx-5"
                                            prepend-icon="fas fa-landmark"
                                          ></v-select></validation-provider
                                      ></v-col>
                                    </v-row>

                                    <validation-provider
                                      v-slot="{ errors }"
                                      name="District"
                                      rules="required"
                                    >
                                      <v-select
                                        ref="name"
                                        prepend-icon="fas fa-map-marker"
                                        class="mx-5"
                                        :class="{
                                          'pt-2': $vuetify.breakpoint.mobile
                                        }"
                                        :error-messages="errors"
                                        v-model="district"
                                        :items="District"
                                        label="District"
                                        hint="Enter Your District"
                                        required
                                      ></v-select>
                                    </validation-provider>

                                    <div class="text-center ma-0 pa-0 mt-4">
                                      <v-btn
                                        :small="$vuetify.breakpoint.mobile"
                                        class="buttonGlass"
                                        color="primary"
                                        @click="nextStep(e1)"
                                        :disabled="secstepinvalid.invalid"
                                      >
                                        Continue
                                      </v-btn>
                                    </div>
                                  </v-form>
                                </div>
                              </validation-observer>
                            </v-card>
                          </v-stepper-content>
                          <v-stepper-content :step="2" class="py-0">
                            <v-card class="pt-0" flat>
                              <validation-observer
                                ref="form3"
                                v-slot="{ invalid }"
                              >
                                <div class="tableData pa-5">
                                  <v-form ref="form2">
                                    <div v-if="nulmSelect === 'SM&ID'">
                                      <v-row
                                        ><v-col class="cols-last">
                                          <v-select
                                            prepend-icon="fas fa-university"
                                            :items="smidSelect"
                                            v-model="smid"
                                            label="Department"
                                          ></v-select
                                        ></v-col>
                                        <v-col
                                          v-if="
                                            smid === 'ALF' ||
                                              smid === 'CLF' ||
                                              smid === 'SHG'
                                          "
                                          class="cols-last"
                                        >
                                          <validation-provider
                                            name="Group Dinhmun"
                                            v-slot="{ errors }"
                                            rules="required"
                                          >
                                            <v-select
                                              :error-messages="errors"
                                              label="Group Dinhmun"
                                              :items="[
                                                'Kehdarh',
                                                'Nung Tha Mek'
                                              ]"
                                              v-model="groupStatus"
                                              prepend-icon="fas fa-users-cog"
                                            ></v-select
                                          ></validation-provider>
                                        </v-col>
                                      </v-row>

                                      <div
                                        v-if="
                                          smid === 'CLF' ||
                                            smid === 'ALF' ||
                                            smid === 'SHG'
                                        "
                                      >
                                        <v-row class="">
                                          <v-col class="cols-last">
                                            <validation-provider
                                              v-slot="{ errors }"
                                              rules="required"
                                              name="Group/Federation Code"
                                            >
                                              <v-text-field
                                                :error-messages="errors"
                                                label="Group/Federation Code"
                                                name="code"
                                                hints="Enter your group or federation code"
                                                v-model="code"
                                                prepend-icon="fas fa-id-card"
                                              >
                                              </v-text-field> </validation-provider
                                          ></v-col>
                                          <v-col class="cols-last">
                                            <validation-provider
                                              v-slot="{ errors }"
                                              rules="required|phoneNo"
                                              name="Member awm zat"
                                            >
                                              <v-text-field
                                                name="Member awm zat"
                                                :error-messages="errors"
                                                label="Member awm zat"
                                                v-model="noOfMembers"
                                                prepend-icon="fas fa-users"
                                              >
                                              </v-text-field> </validation-provider
                                          ></v-col>
                                        </v-row>
                                        <v-row>
                                          <v-col class="cols-last"
                                            ><v-menu
                                              :close-on-content-click="false"
                                              ref="menu"
                                              transition="scale-transition"
                                              offset-y
                                              min-width="290px"
                                            >
                                              <template
                                                v-slot:activator="{
                                                  on,
                                                  attrs
                                                }"
                                              >
                                                <validation-provider
                                                  v-slot="{ errors }"
                                                  rules="required"
                                                  name="Pawl Din Ni"
                                                >
                                                  <v-text-field
                                                    v-model="doe_input"
                                                    class=""
                                                    :error-messages="errors"
                                                    label="Pawl din Ni-Thla-Kum"
                                                    prepend-icon="fa fa-calendar-alt"
                                                    type="text"
                                                    color="primary accent-3"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                  ></v-text-field
                                                ></validation-provider>
                                              </template>
                                              <v-date-picker
                                                color="primary"
                                                v-model="doe"
                                                min="1950-01-01"
                                                @change="saveStart"
                                              ></v-date-picker> </v-menu
                                          ></v-col>
                                          <v-col class="cols-last">
                                            <validation-provider
                                              v-slot="{ errors }"
                                              rules="required"
                                              name="Revolving Fund Dinhmun"
                                            >
                                              <v-select
                                                :error-messages="errors"
                                                label="Revolving fund Dinhmun"
                                                v-model="fundsRecieved"
                                                :items="['Dawng', 'Dawnglo']"
                                                prepend-icon="fas fa-rupee-sign"
                                              ></v-select>
                                            </validation-provider>
                                          </v-col>
                                        </v-row>
                                      </div>
                                    </div>
                                    <div v-if="nulmSelect === 'SUH'">
                                      <v-select
                                        :items="suhSelect"
                                        v-model="suh"
                                        label="Enter your Department"
                                      ></v-select>
                                      <div class=""></div>
                                      <div v-if="suh === 'SMA'"></div>
                                    </div>
                                    <UserGrievances
                                      :nulmSelect="nulmSelect"
                                      :sep="grievance"
                                      :estp="grievance"
                                      :susv="grievance"
                                    ></UserGrievances>
                                    <div>
                                      <v-checkbox
                                        :disabled="invalid"
                                        :dense="$vuetify.breakpoint.mobile"
                                        v-model="agreeChecked"
                                        label="Ka form submit hi ka hriat chinah a dik thlap tih ka pawm e"
                                      ></v-checkbox>
                                    </div>
                                    <div class="text-center mb-2">
                                      <v-btn
                                        :small="$vuetify.breakpoint.mobile"
                                        class="buttonGlass"
                                        color="primary"
                                        @click="nextStep(e1)"
                                        :disabled="!agreeChecked"
                                      >
                                        Continue
                                      </v-btn>
                                    </div>
                                  </v-form>
                                </div>
                              </validation-observer>
                            </v-card>
                          </v-stepper-content>
                          <v-stepper-content :step="3" class="stepper-content ">
                            <v-card elevation="0 " class="mt-0">
                              <div class="tableData mr-3 pt- mt-0">
                                <v-tabs
                                  v-model="tab"
                                  color="primary"
                                  centered
                                  icons-and-text
                                  dense
                                  background-opacity="100"
                                  class=""
                                >
                                  <v-tab align-with-title>
                                    <v-btn
                                      class="mx-1"
                                      fab
                                      color="grey"
                                      outlined
                                    >
                                      <v-icon color="grey" dense
                                        >fab fa-google</v-icon
                                      >
                                    </v-btn>
                                  </v-tab>
                                  <v-tab align-with-title>
                                    <v-btn
                                      class="mx-1"
                                      fab
                                      color="grey"
                                      outlined
                                    >
                                      <v-icon color="grey" dense
                                        >fas fa-phone</v-icon
                                      >
                                    </v-btn>
                                  </v-tab>
                                  <v-tab align-with-title>
                                    <v-btn
                                      class="mx-1"
                                      fab
                                      color="grey"
                                      outlined
                                    >
                                      <v-icon color="grey" dense
                                        >fas fa-envelope</v-icon
                                      >
                                    </v-btn>
                                  </v-tab>
                                </v-tabs>
                                <v-tabs-items v-model="tab" dense>
                                  <v-tab-item>
                                    <validation-observer
                                      v-slot="{ invalid }"
                                      ref="google"
                                    >
                                      <v-form class="mt-6" ref="form3">
                                        <div align="center" class="mt-5 mb-5">
                                          <v-btn
                                            :disabled="invalid"
                                            class="buttonGlass"
                                            color="primary accent-3"
                                            v-on:click="signUp(tab)"
                                            >SIGN IN</v-btn
                                          >
                                        </div>
                                      </v-form>
                                    </validation-observer>
                                  </v-tab-item>
                                  <v-tab-item>
                                    <v-form class="pa-5">
                                      <validation-observer>
                                        <v-row>
                                          <v-col cols="12" md="6" class="pa-0">
                                            <validation-provider
                                              class="ma-0 pa-0"
                                              v-slot="{ errors }"
                                              name="Phone No"
                                              rules="phonerules||required"
                                            >
                                              <v-text-field
                                                class="mx-8"
                                                prefix="91"
                                                prepend-icon="fas fa-phone"
                                                :error-messages="errors"
                                                v-model="phNo"
                                                placeholder="Phone Number"
                                              ></v-text-field>
                                            </validation-provider>
                                          </v-col>
                                          <v-col cols="12" md="6" class="pa-0">
                                            <v-text-field
                                              class="mx-8"
                                              v-model="otp"
                                              :disabled="
                                                otpState !== 'Resend OTP'
                                              "
                                              required
                                              placeholder="OTP"
                                            ></v-text-field>
                                          </v-col>
                                        </v-row>
                                        <v-row class="mx-4 my-0">
                                          <v-col class="text-center">
                                            <v-btn
                                              class="buttonGlass"
                                              dark
                                              color="primary"
                                              @click="sendOtpNewUser()"
                                              >Get OTP</v-btn
                                            >
                                          </v-col>

                                          <v-col class="text-center">
                                            <v-btn
                                              :disabled="
                                                otpState !== 'Resend OTP'
                                              "
                                              class="buttonGlass"
                                              color="primary"
                                              @click="signUp(tab)"
                                              >Verify</v-btn
                                            >
                                          </v-col>
                                        </v-row>
                                      </validation-observer>
                                    </v-form>
                                  </v-tab-item>
                                  <v-tab-item>
                                    <v-card-text class="pa-0 ma-0">
                                      <validation-observer
                                        ref="observer"
                                        v-slot="{ invalid }"
                                      >
                                        <v-form
                                          @submit.prevent="submit"
                                          ref="form1"
                                          autocomplete="off"
                                        >
                                          <validation-provider
                                            v-slot="{ errors }"
                                            name="Email"
                                            rules="required|email"
                                          >
                                            <v-text-field
                                              :class="{
                                                'pt-2':
                                                  $vuetify.breakpoint.mobile
                                              }"
                                              :dense="
                                                $vuetify.breakpoint.mobile
                                              "
                                              :error-messages="errors"
                                              v-model="sEmail"
                                              autocomplete="false"
                                              class="mx-8"
                                              label="Email"
                                              name="email"
                                              prepend-icon="fas fa-envelope"
                                              type="text"
                                              color="primary accent-3"
                                            ></v-text-field>
                                          </validation-provider>
                                          <validation-provider
                                            v-slot="{ errors }"
                                            name="Password"
                                            :rules="{
                                              required: true,
                                              one: 6
                                            }"
                                          >
                                            <v-text-field
                                              :class="{
                                                'pt-2':
                                                  $vuetify.breakpoint.mobile
                                              }"
                                              :dense="
                                                $vuetify.breakpoint.mobile
                                              "
                                              autocomplete="off"
                                              v-model="sPassword"
                                              :error-messages="errors"
                                              class="mx-8"
                                              label="Password"
                                              name="sPassword"
                                              prepend-icon="fas fa-unlock-alt"
                                              color="primary accent-3"
                                              :append-icon="
                                                seenPassSignUp
                                                  ? 'fas fa-eye'
                                                  : 'fas fa-eye-slash'
                                              "
                                              @click:append="
                                                () =>
                                                  (seenPassSignUp = !seenPassSignUp)
                                              "
                                              :type="
                                                seenPassSignUp
                                                  ? 'text'
                                                  : 'password'
                                              "
                                            ></v-text-field>
                                          </validation-provider>
                                          <validation-provider
                                            v-slot="{ errors }"
                                            name="Password Confirm-na"
                                            rules="required|password:@Password"
                                          >
                                            <v-text-field
                                              :class="{
                                                'pt-2':
                                                  $vuetify.breakpoint.mobile
                                              }"
                                              :dense="
                                                $vuetify.breakpoint.mobile
                                              "
                                              v-model="sPassword2"
                                              :error-messages="errors"
                                              class="mr-8 ml-15"
                                              label="Confirm Password"
                                              name="Confirm Password"
                                              color="primary accent-3"
                                              :type="
                                                seenPassSignUp
                                                  ? 'text'
                                                  : 'password'
                                              "
                                            ></v-text-field
                                          ></validation-provider>
                                          <v-checkbox
                                            class="mr-8 ml-15"
                                            v-model="spassword2check"
                                            label="Ka e-mail hman hi keima email ngei a ni tih ka pawm e"
                                          ></v-checkbox>

                                          <div class="text-center mb-4">
                                            <v-btn
                                              :small="
                                                $vuetify.breakpoint.mobile
                                              "
                                              class="buttonGlass"
                                              color="primary"
                                              @click="signUp(tab)"
                                              :disabled="
                                                invalid || !spassword2check
                                              "
                                            >
                                              Continue
                                            </v-btn>
                                          </div>
                                        </v-form></validation-observer
                                      >
                                    </v-card-text>
                                  </v-tab-item>
                                </v-tabs-items>
                              </div>
                            </v-card>
                          </v-stepper-content>
                        </v-stepper-items>
                      </v-stepper>
                    </v-card>
                  </v-col>
                </v-row>
              </v-window-item>
            </v-window>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-spacer></v-spacer>
  </v-app>
</template>
<script>
import UserGrievances from '../routes/UserGrievances.vue'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import {
  min,
  required,
  email,
  alpha_spaces,
  numeric
} from 'vee-validate/dist/rules'
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from 'vee-validate'
setInteractionMode('eager')
extend('password', {
  params: ['target'],
  validate (value, { target }) {
    return value === target
  },
  message: 'Password leh a confirm-na a inmillo'
})
extend('required', {
  ...required,
  message: '{_field_} a ruak theilo'
})
extend('one', {
  ...min,
  message: 'password needs to be atleast 6 chararcter.'
})
extend('confirm', {
  ...min,
  message: 'Password hi paruk tal tur'
})
extend('email', {
  ...email,
  message: 'Email diktak hman tur'
})
extend('name', {
  ...alpha_spaces,
  message: 'Hmingah alphabet bak a hman theihloh'
})
extend('phoneNo', {
  ...numeric,
  message: 'Number bak a hman theihloh'
})
extend(
  'phonerules',
  value => {
    return value < 10000000000 && value > 999999999
  },
  { message: 'Phone Number hi digit sawm tur a ni' }
)
export default {
  components: {
    UserGrievances,
    ValidationProvider,
    ValidationObserver
  },
  name: 'Login',
  data: () => ({
    grievance: {},

    spassword2check: false,

    otpState: 'Get OTP',
    otpLoginStep: 0,
    phoneUser: null,
    phNo: '',
    appVerifier: '',
    otp: '',
    tab: 1,

    userDistrict: null,
    progressValue: '0',
    progressMessages: '',
    groupStatus: '',
    fundsRecieved: '',
    doe: '',
    code: '',
    noOfMembers: null,
    nulmSelect: null,
    NULM: ['SM&ID', 'SUH', 'SEP', 'SUSV', 'ESTP', 'CMMU', 'SMMU'],
    smidSelect: ['CLF', 'ALF', 'SHG'],
    smid: null,
    suhSelect: ['SMC', 'SMA'],
    suh: null,
    e1: 1,

    steps: [
      {
        name: 'User Details',
        step: 1
      },
      {
        name: 'Group Details',
        step: 2
      },
      {
        name: 'Authentication',
        step: 3
      }
    ],
    name: '',
    address: '',
    district: '',
    phoneNo: '',
    error: null,
    formData: '',
    showLoading: false,
    step: 1,
    seenPassSignUp: false,
    seenPass: false,
    email: '',
    password: '',
    sEmail: '',
    sPassword: '',
    sPassword2: '',

    agreeChecked: null,
    maxSteps: 1,
    District: [
      'Aizawl',
      'Kolasib',
      'Lawngtlai',
      'Lunglei',
      'Mamit',
      'Saiha',
      'Serchhip',
      'Champhai',
      'Hnahthial',
      'Khawzawl',
      'Saitual'
    ],
    userUID: ''
  }),
  methods: {
    hovertest (hover) {
      if (hover) return 'white'
      else return 'grey'
    },
    async sendOtp () {
      if (this.phNo.length != 10) {
        alert('Invalid Phone Number Format !')
      } else {
        //
        let countryCode = '+91' //india
        let phoneNumber = countryCode + this.phNo
        //
        let appVerifier = this.appVerifier
        //
        await firebase
          .auth()
          .signInWithPhoneNumber(phoneNumber, appVerifier)
          .then(async confirmationResult => {
            window.confirmationResult = confirmationResult

            this.otpLoginStep = 1
            this.otpState = 'Resend OTP'
          })
          .catch(async error => {
            // Error; SMS not sent
            // ...
            alert(error, 'Error ! SMS not sent'),
              (this.otpLoginStep = 0),
              (this.otpState = 'Send OTP')
          })
      }
    },
    verifyOtp () {
      this.progressMessages = 'Logging you in please wait'
      this.progressValue = '0'

      if (this.phNo.length != 10 || this.otp.length != 6) {
        alert('Invalid Phone Number/OTP Format !')
      } else {
        this.showLoading = true
        //
        // let vm = this;
        let code = this.otp
        //
        window.confirmationResult
          .confirm(code)
          .then(async res => {
    res
            this.otpLoginStep = 0

            firebase.auth().onAuthStateChanged(user => {
              if (user) {
                // User logged in already or has just logged in.
                this.finalSignIn(user.uid)
                this.progressMessages = 'OTP Verified'
              } else {
                alert('No user found')
              }
            })
          })
          .catch(async error => {
            if (error.code === 'auth/invalid-verification-code') {
              this.progressMessages =
                'Invalid OTP Please Check Your OTP Or Retry'
              setTimeout(() => {
                this.showLoading = false
              }, 4000)
            } else {
              this.progressMessages = error.message
              setTimeout(() => {
                this.showLoading = false
              }, 4000)
            }

            this.otpLoginStep = 1
            this.otpState = 'Send OTP'
            this.progressValue = '0'
          })
      }
    },
    verifyOtpNewUser () {
      if (this.phNo.length != 10 || this.otp.length != 6) {
        alert('Invalid Phone Number/OTP Format !')
      } else {
        //
        let vm = this
        let code = this.otp
        //
        window.confirmationResult
          .confirm(code)
          .then(async result => {
            this.progressMessages = 'Account Created'
            this.progressValue = '30'
            vm.userUID = result.user.uid
        
            await vm.finalSignUp(vm.data, result.user.uid)
          })
          .catch(async error => {
            if (error.code === 'auth/invalid-verification-code') {
              this.progressMessages =
                'Invalid OTP Please Check Your OTP Or Retry'
              setTimeout(() => {
                this.showLoading = false
              }, 4000)
            } else {
              this.progressMessages = error.message
              setTimeout(() => {
                this.showLoading = false
              }, 4000)
            }
          })
      }
    },
    initReCaptcha (target) {
      setTimeout(() => {
        // let vm = this;
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(target, {
          size: 'invisible',
          callback: function () {
           
          },
          'expired-callback': function () {
            // Response expired. Ask user to solve reCAPTCHA again.
            // ...
          }
        })
        //
        this.appVerifier = window.recaptchaVerifier
      }, 1000)
    },
    async sendOtpNewUser () {
      if (this.phNo.length != 10) {
        alert('Invalid Phone Number Format !')
      } else {
        //
        let countryCode = '+91' //india
        let phoneNumber = countryCode + this.phNo
        //
        let appVerifier = this.appVerifier
        //
        await firebase
          .auth()
          .signInWithPhoneNumber(phoneNumber, appVerifier)
          .then(async confirmationResult => {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            window.confirmationResult = confirmationResult
            //

            this.otpState = 'Resend OTP'
          })
          .catch(async function (error) {
            // Error; SMS not sent
            // ...
            alert(error, 'Error ! SMS not sent')
          })
      }
    },

    showGoogle () {
      var provider = new firebase.auth.GoogleAuthProvider()

      firebase
        .auth()
        .signInWithPopup(provider)
        .then(result => {
          /** @type {firebase.auth.OAuthCredential} */
          var credential = result.credential

          // This gives you a Google Access Token. You can use it to access the Google API.
          var token = credential.accessToken
          token
          // The signed-in user info.
          var user = result.user

          // ...
          this.finalSignIn(user.uid)
        })
        .catch(error => {
          // Handle Errors here.
   error
          // var errorCode = error.code;
          // var errorMessage = error.message;
          // // The email of the user's account used.
          // var email = error.email;
          // // The firebase.auth.AuthCredential type that was used.
          // var credential = error.credential;
          // // ...
        })
    },
    retScroll () {},

    saveStart (date) {
      this.$refs.menu.save(date)
    },
    nameUpper (name) {
      this.name = name.toUpperCase()
    },
    async signIn () {
      localStorage.clear()
      this.showLoading = true
      this.error = ''
      this.progressMessages = 'Logging you in please wait'
      this.progressValue = '0'
      this.showLoading = true
      await firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(user => {
          this.finalSignIn(user.user.uid)

          // if (userData) {
          //   this.progressValue = "50";
          //   this.progressMessages = "Retrieving your documents...";
          // }
          // else {
          //   this.progressMessages = "Error Please Retry";
          // }
        })
        .catch(error => {
          if (error.code === 'auth/wrong-password') {
            this.error = 'Please enter correct password'
          }
          if (error.code === 'auth/nerwork-request-failed') {
            this.error =
              'You need to be connected to the Internet to sign in to your account'
          }
          if (error.code === 'auth/user-not-found') {
            this.error =
              'This email is not yet registered. You have to register as a New User'
          }

          this.showLoading = false
        })
    },
    finalSignIn (uid) {
      this.$store.dispatch('unbinduserNotificationsLists')
      let userCollection, userNulm
      if (this.nulmSelect === 'SMMU' || this.nulmSelect === 'CMMU') {
        userNulm = 'SM&ID'
        userCollection = this.nulmSelect.toLowerCase()
        localStorage.setItem(
          'userPendingCollection',
          this.nulmSelect
            .toLowerCase()
            .concat('_', this.userDistrict.toLowerCase())
            .trim()
        )
      } else if (
        this.nulmSelect === 'SM&ID' ||
        this.nulmSelect === 'SUH' ||
        this.nulmSelect === 'SEP' ||
        this.nulmSelect === 'SUSV' ||
        this.nulmSelect === 'ESTP'
      ) {
        userCollection = this.nulmSelect
          .toLowerCase()
          .replace('&', 'n')
          .concat('_', this.userDistrict.toLowerCase())
        userNulm = this.nulmSelect
      }
      localStorage.setItem('userDb', userCollection)
      localStorage.setItem('userNulmPref', userNulm)
      localStorage.setItem('mini', false)
      localStorage.setItem('dark', false)
      this.progressValue = '50'

      firebase
        .firestore()
        .collection(userCollection)
        .doc(uid)
        .get()
        .then(doc => {
          if (doc.exists) {
            if (doc.data().nulm === 'SMMU' || doc.data().nulm === 'CMMU') {
              localStorage.setItem('userDeptPref', 'CLF')
              localStorage.setItem(
                'userNulmPref',
                'smnid'.concat('_', this.userDistrict.toLowerCase())
              )
            } else if (doc.data().nulm === 'SM&ID') {
              if (doc.data().department === 'CLF') {
                localStorage.setItem('userDeptPref', 'ALF')
              }
              if (doc.data().department === 'ALF') {
                localStorage.setItem('userDeptPref', 'SHG')
              }
              if (doc.data().department === 'SHG') {
                localStorage.setItem('userDeptPref', 'SHG')
              }
            } else if (doc.data().nulm === 'SUH') {
              if (doc.data().department === 'SMC') {
                localStorage.setItem('userDeptPref', 'SMA')
              }
            }

            this.$store.commit('changeUserInfo', doc.data())
            this.$store.commit('changeUserDistrict', doc.data().district)
            this.$store.commit(
              'changeUserNulm',
              doc.data().nulm.replace('&', 'n')
            )
            localStorage.setItem('district', doc.data().district.toLowerCase())
            localStorage.setItem('nulm', doc.data().nulm)
            this.$router.replace('/Home')
          } else {
            firebase
              .firestore()
              .collection('usersUnapproved')
              .doc(uid)
              .get()
              .then(doc => {
                if (doc.exists) {
                  this.error = this.progressMessages =
                    'Contact your CMMU/SMMU(for CMMU) to approve your account'
                } else {
                  this.error = this.progressMessages =
                    'Contact CMMU as you have created your account without any documents'
                }
                firebase.auth().signOut()
              })
          }
        })
        .catch(err => {
          if (err.code === 'permission-denied') {
            this.error = 'Please wait for your CMMU/SMMU approval'
            this.showLoading = false
            firebase.auth().signOut()
          }
        })
    },
    nextStep (n) {
      if (n === this.steps) {
        this.e1 = 1
      } else {
        this.e1 = n + 1
      }
    },
    hints (number) {
      switch (number) {
        case 1:
          return "Enter your Email and Password with your DAY-NULM Category. You also have to re-enter the password again. Then click 'Continue'"
        case 2:
          return 'Enter your Name followed by Address, District and Phone number. Please add 91 before your Phone no. You can then further proceed'
        case 3:
          return 'You have to enter furhther departmental details and You must check the checkbox to agree. Then you can submit the form'
      }
    },

    async signUp (step) {
      this.error = ''
      this.showLoading = true
      this.progressMessages = 'Creating Your account'
      this.progressValue = '20'

      let Timeline = {
        date: firebase.firestore.Timestamp.now(),
        name: 'Account Created'
      }

      this.data = {
        name: this.name,
        email: this.sEmail,
        phone: this.phoneNo,
        address: this.address,
        district: this.district,
        nulm: this.nulmSelect,
        created: firebase.firestore.Timestamp.now(),
        timeLineItem: [Timeline]
      }
      if (this.nulmSelect === 'SM&ID') {
        this.data.department = this.smid
        this.data.groupStatus = this.groupStatus
        this.data.code = this.code
        this.data.members = this.noOfMembers
        this.data.dateEstablished = this.doe_input
        this.data.fundsRecieved = this.fundsRecieved
      } else if (this.nulmSelect == 'SUH') {
        this.data.department = this.suh
      } else if (['ESTP', 'SUSV', 'SEP'].includes(this.nulmSelect)) {
        this.data.grievance = [this.grievance]
      }
      let init

      switch (step) {
        case 0:
          var provider = new firebase.auth.GoogleAuthProvider()
          firebase.auth().languageCode = 'en'

          init = await firebase
            .auth()
            .signInWithPopup(provider)
            .then(async () => {
              this.progressMessages = 'Account Created'
              this.progressValue = '30'
            })
            .catch(async err => {
              this.progressMessages = err.message
              this.error = err.message
              setTimeout(() => {
                this.showLoading = false
              }, 4000)
            })
          break
        case 1:
          this.userUID = ''
          this.data.email = ''
          init = await this.verifyOtpNewUser()

          break
        case 2:
          init = await firebase
            .auth()
            .createUserWithEmailAndPassword(this.sEmail, this.sPassword)
            .then(() => {
              this.progressMessages = 'Account Created'
              this.progressValue = '30'
            })
            .catch(err => {
              this.progressMessages = err.message
              this.error = err.message
              setTimeout(() => {
                this.showLoading = false
              }, 4000)
            })
          break
      }
      init
      if (step != 1)
        this.finalSignUp(this.data, firebase.auth().currentUser.uid)

      // this.$refs.form1.reset();
      // this.$refs.form2.reset();
      // this.$refs.form3.reset();
    },
    async finalSignUp (data, id) {
      if (!this.error) {
        this.progressMessages = 'Creating Your Documents'
        this.progressValue = '40'
        let docCreate = await firebase
          .firestore()
          .collection('usersUnapproved')
          .doc(id)
          .set(data)
        if (docCreate) {
          this.progressMessages = 'Writing your Documents'
          this.progressValue = '60'
        }
        await firebase.auth().currentUser.updateProfile({
          displayName: this.name
        })

        if (this.tab != 1)
          this.progressMessages = 'Sending your e-mail verification'
        else this.progressMessages = 'Finalizing your account'

        this.progressValue = '80'
        if (this.tab != 1)
          await firebase
            .auth()
            .currentUser.sendEmailVerification()
            .then(() => {
              this.progressMessages = 'Confirm your email account to proceed'
            })
            .catch(err => {
              this.error = err.message
            })
        this.progressMessages = 'Finished creating your account'
        firebase
          .auth()
          .signOut()
          .then(
            () =>
              (this.progressMessages = 'Please wait approval from your CMMU')
          )
        this.progressValue = '100'
        setTimeout(() => {
          this.showLoading = false
          this.progressMessages = ''
          this.step = 1
        }, 3000)
      }
    }
  },
  created () {
    this.initReCaptcha('recaptcha-container')

    setTimeout(() => {
      let main = document.getElementById('login_main')
      // main = main[0]
      let style = document.createElement('style')
      document.head.appendChild(style)

      if (main.clientWidth < main.scrollWidth)
        style.sheet.insertRule('#login_main{overflow:scroll}')
      if (main.clientHeight < main.scrollHeight)
        style.sheet.insertRule('#login_main{overflow:scroll}')
    }, 500)
  },

  computed: {
    doe_input () {
      if (this.doe != '') {
        return this.doe
          .split('-')[2]
          .concat('-', this.doe.split('-')[1], '-', this.doe.split('-')[0])
      } else return ''
    },
    maxStep () {
      let maxStep = 1
      if (this.e1 > maxStep) {
        maxStep = this.e1
        return maxStep
      } else {
        return maxStep
      }
    }
  },
  watch: {
    step () {
      if (this.$refs['google_login'] && this.tab === 0)
        this.$refs.google_login.reset()
      if (this.$refs['phone_login'] && this.tab === 1)
        this.$refs.phone_login.reset()
      if (this.$refs['mail_login'] && this.tab === 2)
        this.$refs.mail_login.reset()
      this.smid = ''
    }
  }
}
</script>
<style scoped>
.logoBanner {
  overflow: visible;

  top: 0.8%;
  height: 3rem;
  font-size: 2rem;
  left: 25%;
  position: absolute;
  z-index: 8 !important;
  background: #29d;
  width: 50%;
  text-align: center;
}
.left {
  content: '';
  left: calc(73% - 0.53rem);
  z-index: 3 !important;
  position: absolute !important;
  top: 1.8rem;
  width: 10vw;
  max-width: 100% !important;
  max-height: 100% !important;
  min-height: 0px !important;
  height: 2rem !important;
  margin: 0;
  padding: 0;

  border-right-width: 3rem;
  border: 1.5rem solid rgba(72, 135, 172, 0.945);

  border-left-color: rgb(199, 186, 186);
  border-left-width: 2.8vw;
  border-top-color: #2a93e9ad;
  border-right-color: transparent;
  border-bottom-color: #2a93e9ad;
}
.right {
  content: '';

  z-index: 3 !important;
  position: absolute !important;
  top: 1.8rem;
  left: 17.5vw;
  width: 10vw;
  max-width: 100% !important;
  max-height: 100% !important;
  min-height: 0px !important;
  height: 2rem !important;
  margin: 0;
  padding: 0;

  border-left-width: 3rem;
  border: 1.5rem solid rgba(72, 135, 172, 0.945);

  border-right-color: rgb(199, 186, 186);
  border-right-width: 2.8vw;
  border-top-color: #2a93e9ad;
  border-left-color: transparent;
  border-bottom-color: #2a93e9ad;
}

.iconBtn {
  position: relative !important;
  top: 50% !important;
  left: 50% !important;
}
.iconBtn:active {
  color: black !important;
}

.fabBtn {
  padding: 3px;
  background-color: #fff !important;
  position: relative !important;
  border-bottom-width: 0.22rem !important;
  border-top-width: 0.22rem !important;
  border-left-width: 0.22rem !important;
  border-right-width: 0.22rem !important;
  transition: 0.5s;
}
.fabBtn::before,
after {
  position: relative !important;
  left: 50%;
  display: none;
}

.fabBtn:hover {
  background-color: #2196f3 !important;
}
.circle {
  position: absolute;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  transition: 1s ease-out;
}

.circle:hover {
  cursor: pointer;
  animation: stroke 3s forwards !important;
}

@keyframes stroke {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
.loginWave {
  background: white;
  opacity: 9;
  background-image: url('/assets/wavesLogin.svg');
}
.contacter {
  margin: 0 auto;
  align-content: center;
  position: absolute;
  bottom: 1rem;
  right: 7rem;
}
.stepper-content {
  margin: 0;
  padding: 0;
}
.cols-last {
  margin: 0 1em 0 1em;
  padding: 0;
}
.window {
  max-height: 90vh;
  min-height: 60vh;
}
.bgSvg {
  background-color: var(--v-primary-darken--20);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1510' height='181.2' viewBox='0 0 1000 120'%3E%3Cg fill='none' stroke='%23ededed' stroke-width='8.6' stroke-opacity='0.65'%3E%3Cpath d='M-500 75c0 0 125-30 250-30S0 75 0 75s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 45c0 0 125-30 250-30S0 45 0 45s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 105c0 0 125-30 250-30S0 105 0 105s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 15c0 0 125-30 250-30S0 15 0 15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500-15c0 0 125-30 250-30S0-15 0-15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 135c0 0 125-30 250-30S0 135 0 135s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3C/g%3E%3C/svg%3E");
}
@media only screen and (max-width: 1000px) {
  .window {
    max-height: 100vh;
    min-height: 50vh;
  }
}
.banner {
  position: relative;
  z-index: 1;
  margin: 80px auto;
  width: 330px;
}
.banner .line {
  margin: 0 0 10px;
  width: 100%;
  height: 78px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
  text-align: center;
  text-transform: uppercase;
  font-size: 3em;
  line-height: 78px;
  transform: skew(0, -15deg);
}
.banner .line:after,
.banner .line:first-child:before {
  position: absolute;
  top: 44px;
  left: 0;
  z-index: -1;
  display: block;
  width: 330px;
  height: 78px;
  border-radius: 4px;
  background: rgba(180, 180, 180, 0.8);
  content: '';
  transform: skew(0, 15deg);
}
.banner .line:first-child:before {
  top: -10px;
  right: 0;
  left: auto;
}
.banner .line:first-child:before,
.banner .line:last-child:after {
  width: 0;
  height: 0;
  border-width: 38px;
  border-style: solid;
  border-color: rgba(180, 180, 180, 0.8) rgba(180, 180, 180, 0.8) transparent
    transparent;
  background: transparent;
}
.banner .line:last-child:after {
  top: 12px;
  border-color: transparent transparent rgba(180, 180, 180, 0.8)
    rgba(180, 180, 180, 0.8);
}
.banner span {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.9);
  color: #666;
  text-shadow: 1px 1px 0 #444;
}
@media only screen and (max-width: 600px) {
  .left,
  .right {
    max-width: 100% !important;
    min-width: 20% !important;
    max-height: 100% !important;
    min-height: 0px !important;
  }
  .right {
    transform: translateX(-50%) translateY(-3%);
  }
  .left {
    transform: translateX(4%) translateY(-3%);
  }
}
</style>
