var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{staticClass:"px-3",model:{value:(_vm.complaintForm),callback:function ($$v) {_vm.complaintForm=$$v},expression:"complaintForm"}},[_c('v-card',[_c('v-card',{staticClass:"mx-auto pa-8",attrs:{"outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticClass:"text-decoration-underline text-decoration-bold text-center mb-5 text-h3"},[_vm._v(" Form IV ")]),_c('div',{staticClass:"text-h6 mb-1 text-center text-decoration-underline"},[_vm._v(" STREET VENDORS TEN AN LUNGAWLOHNA THU THLENNA (Street Vendors Complaint) ")]),_c('v-form',{staticClass:"mt-4"},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Hming","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Hming","prepend-icon":"fas fa-user","placeholder":"Form apply-tu hming"},model:{value:(_vm.susv.name),callback:function ($$v) {_vm.$set(_vm.susv, "name", $$v)},expression:"susv.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Address/chenna","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Address Kimchang","placeholder":"House No, Veng, Khua, District","prepend-icon":"fas fa-map-marker"},model:{value:(_vm.susv.address),callback:function ($$v) {_vm.$set(_vm.susv, "address", $$v)},expression:"susv.address"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Street Vendor ID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Street Vendor ID Number DAY-NULM pekchhuah","placeholder":"Id No","prepend-icon":"fas fa-id-card"},model:{value:(_vm.susv.id),callback:function ($$v) {_vm.$set(_vm.susv, "id", $$v)},expression:"susv.id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Certificate of Vending No. leh pekchhuah tanni","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"","placeholder":"Street Vendor ID","prepend-icon":"fas fa-file"},model:{value:(_vm.susv.cert),callback:function ($$v) {_vm.$set(_vm.susv, "cert", $$v)},expression:"susv.cert"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Thilzawrhna hmun","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"","placeholder":"Thil zawrhna hmun","prepend-icon":"fas fa-map-marker-alt"},model:{value:(_vm.susv.storePlace),callback:function ($$v) {_vm.$set(_vm.susv, "storePlace", $$v)},expression:"susv.storePlace"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Thilzawrhna hmun Ward no/VC area No","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Thilzawrhna hmun Ward no/VC area No","placeholder":"Area No","prepend-icon":"fas fa-warehouse"},model:{value:(_vm.susv.storePlaceId),callback:function ($$v) {_vm.$set(_vm.susv, "storePlaceId", $$v)},expression:"susv.storePlaceId"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Thilzawrh dan kalphung","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":[
                      'Thuthmun nghet neilo a kal kual',
                      'Thuthmun nghet nei/Hawkers',
                      'A dang'
                    ],"label":"Thilzawrh dan kalphung","prepend-icon":"fas fa-tasks"},model:{value:(_vm.susv.task),callback:function ($$v) {_vm.$set(_vm.susv, "task", $$v)},expression:"susv.task"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Lungawi lohna thlentu thuneitu te","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":[
                      'VC',
                      'LC',
                      'Police',
                      'Town Vending Committee',
                      'Midang'
                    ],"label":"Lungawi lohna thlentu thuneitu te","prepend-icon":"fas fa-id-badge"},model:{value:(_vm.susv.grievanceTo),callback:function ($$v) {_vm.$set(_vm.susv, "grievanceTo", $$v)},expression:"susv.grievanceTo"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Lungawi lohna thlentu thuneitu te","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-textarea',{attrs:{"error-messages":errors,"label":"Lungawih loh nachhan kimchang","placeholder":"Lungawih loh nachhan","prepend-icon":"fas fa-comments","rows":1},model:{value:(_vm.susv.grievance),callback:function ($$v) {_vm.$set(_vm.susv, "grievance", $$v)},expression:"susv.grievance"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('div',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"buttonGlass",attrs:{"color":"primary","disabled":invalid},on:{"click":function($event){_vm.complaintForm = false}}},'v-btn',attrs,false),on),[_vm._v(" OK ")])]}}],null,true)},[_c('span',[_vm._v(" Form I duh anga i ziah fel vek hunah OK click tur ")])])],1)]}}])})],1)],1)],1)],1)],1)],1),(_vm.nulmSelect === 'SEP')?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Bank hming"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{attrs:{"dense":"","readonly":_vm.$route.name == 'Notifications',"items":_vm.bankArr,"placeholder":"Eg: State Bank of India","error-messages":errors,"label":"Bank Hming","prepend-icon":"fas fa-university","type":"text","color":"primary accent-3"},model:{value:(_vm.sep.bankName),callback:function ($$v) {_vm.$set(_vm.sep, "bankName", $$v)},expression:"sep.bankName"}})]}}],null,false,1033837795)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Bank Branch"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","readonly":_vm.$route.name == 'Notifications',"label":"Bank Branch Hming","prepend-icon":"fas fa-archway","error-messages":errors,"placeholder":"Eg: Dawrpui Branch"},model:{value:(_vm.sep.bankBranch),callback:function ($$v) {_vm.$set(_vm.sep, "bankBranch", $$v)},expression:"sep.bankBranch"}})]}}],null,false,3166695877)})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"Feedback/Complaint"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-textarea',{attrs:{"readonly":_vm.$route.name == 'Notifications',"hide-details":_vm.$route.name == 'Notifications',"label":"Feedback/Complaint","rows":"3","prepend-icon":"fa fa-file-text-o","error-messages":errors,"placeholder":"Harsatna neihte","hint":"I harsatna neihte hetah hian ziah luh tur a ni"},model:{value:(_vm.sep.feedback),callback:function ($$v) {_vm.$set(_vm.sep, "feedback", $$v)},expression:"sep.feedback"}})]}}],null,false,1121063180)})],1)],1)],1):_vm._e(),(_vm.nulmSelect === 'SUSV')?_c('div',[_c('v-row',[(_vm.$route.name == 'Login')?_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"buttonGlass",attrs:{"color":"primary"},on:{"click":function($event){_vm.complaintForm = true}}},'v-btn',attrs,false),on),[_vm._v("Submit Complaint")])]}}],null,false,377254005)},[_c('span',[_vm._v(" Complaint thehluh duh chuan click tur ")])])],1):_vm._e(),(_vm.$route.name !== 'Login')?_c('v-col',[_c('v-card',{staticClass:"mx-auto pa-8",attrs:{"outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticClass:"text-decoration-underline text-decoration-bold text-center text-h4 mb-5"},[_vm._v(" Form IV ")]),_c('div',{staticClass:"text-h6 mb-1 text-center text-decoration-underline"},[_vm._v(" STREET VENDORS TEN AN LUNGAWLOHNA THU THLENNA (Street Vendors Complaint) ")]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var invalid = ref.invalid;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Hming","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Hming","prepend-icon":"fas fa-user","placeholder":"Form apply-tu hming"},model:{value:(_vm.susv.name),callback:function ($$v) {_vm.$set(_vm.susv, "name", $$v)},expression:"susv.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Address/chenna","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Address Kimchang","placeholder":"House No, Veng, Khua, District","prepend-icon":"fas fa-map-marker"},model:{value:(_vm.susv.address),callback:function ($$v) {_vm.$set(_vm.susv, "address", $$v)},expression:"susv.address"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Street Vendor ID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Street Vendor ID Number DAY-NULM pekchhuah","placeholder":"Id No","prepend-icon":"fas fa-id-card"},model:{value:(_vm.susv.id),callback:function ($$v) {_vm.$set(_vm.susv, "id", $$v)},expression:"susv.id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Certificate of Vending No. leh pekchhuah tanni","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"","placeholder":"Street Vendor ID","prepend-icon":"fas fa-file"},model:{value:(_vm.susv.cert),callback:function ($$v) {_vm.$set(_vm.susv, "cert", $$v)},expression:"susv.cert"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Thilzawrhna hmun","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"","placeholder":"Thil zawrhna hmun","prepend-icon":"fas fa-map-marker-alt"},model:{value:(_vm.susv.storePlace),callback:function ($$v) {_vm.$set(_vm.susv, "storePlace", $$v)},expression:"susv.storePlace"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Thilzawrhna hmun Ward no/VC area No","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Thilzawrhna hmun Ward no/VC area No","placeholder":"Area No","prepend-icon":"fas fa-warehouse"},model:{value:(_vm.susv.storePlaceId),callback:function ($$v) {_vm.$set(_vm.susv, "storePlaceId", $$v)},expression:"susv.storePlaceId"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Thilzawrh dan kalphung","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":[
                      'Thuthmun nghet neilo a kal kual',
                      'Thuthmun nghet nei/Hawkers',
                      'A dang'
                    ],"label":"Thilzawrh dan kalphung","prepend-icon":"fas fa-tasks"},model:{value:(_vm.susv.task),callback:function ($$v) {_vm.$set(_vm.susv, "task", $$v)},expression:"susv.task"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Lungawi lohna thlentu thuneitu te","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":[
                      'VC',
                      'LC',
                      'Police',
                      'Town Vending Committee',
                      'Midang'
                    ],"label":"Lungawi lohna thlentu thuneitu te","prepend-icon":"fas fa-id-badge"},model:{value:(_vm.susv.grievanceTo),callback:function ($$v) {_vm.$set(_vm.susv, "grievanceTo", $$v)},expression:"susv.grievanceTo"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":"Lungawi lohna thlentu thuneitu te","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-textarea',{attrs:{"error-messages":errors,"label":"Lungawih loh nachhan kimchang","placeholder":"Lungawih loh nachhan","prepend-icon":"fas fa-comments","rows":1},model:{value:(_vm.susv.grievance),callback:function ($$v) {_vm.$set(_vm.susv, "grievance", $$v)},expression:"susv.grievance"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('div',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"buttonGlass",attrs:{"color":"primary","disabled":invalid},on:{"click":function($event){_vm.complaintForm = false}}},'v-btn',attrs,false),on),[_vm._v(" OK ")])]}}],null,true)},[_c('span',[_vm._v(" Form I duh anga i ziah fel vek hunah OK click tur ")])])],1)]}}],null,false,358927974)})],1)],1)],1)],1):_vm._e()],1)],1):_vm._e(),(_vm.nulmSelect === 'ESTP')?_c('div',{staticClass:"ma-0 pa-0"},[_c('validation-observer',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Training Center","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","label":"Training Center","prepend-icon":"fas fa-university","error-messages":errors,"placeholder":"Training Center"},model:{value:(_vm.estp.trainingCenter),callback:function ($$v) {_vm.$set(_vm.estp, "trainingCenter", $$v)},expression:"estp.trainingCenter"}})]}}],null,false,1269037974)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Course","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","label":"Course","prepend-icon":"fas fa-book","error-messages":errors,"placeholder":"Course"},model:{value:(_vm.estp.course),callback:function ($$v) {_vm.$set(_vm.estp, "course", $$v)},expression:"estp.course"}})]}}],null,false,2713514669)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"District","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{attrs:{"dense":"","label":"District","items":_vm.District,"prepend-icon":"fas fa-map-marker","error-messages":errors,"placeholder":"Training Center"},model:{value:(_vm.estp.trainingCenterDistrict),callback:function ($$v) {_vm.$set(_vm.estp, "trainingCenterDistrict", $$v)},expression:"estp.trainingCenterDistrict"}})]}}],null,false,4264386278)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Subsidy dawn dan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{attrs:{"dense":"","label":"Subsidy","items":['Dawng Tha', 'Dawng Tha lo'],"prepend-icon":"fas fa-inr","error-messages":errors,"placeholder":"Dawng tha/thalo"},model:{value:(_vm.estp.subsidy),callback:function ($$v) {_vm.$set(_vm.estp, "subsidy", $$v)},expression:"estp.subsidy"}})]}}],null,false,1335009496)})],1)],1),_c('v-row',{staticClass:"my-0 py-0"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"Feedback/Complaint"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Feedback/Complaint","rows":"3","prepend-icon":"fa fa-file-text-o","error-messages":errors,"placeholder":"Harsatna neihte","hint":"I harsatna neihte hetah hian ziah luh tur a ni"},model:{value:(_vm.estp.feedback),callback:function ($$v) {_vm.$set(_vm.estp, "feedback", $$v)},expression:"estp.feedback"}})]}}],null,false,3500671313)})],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }